/* src/screens/MyDayScreen.css */
.myDay-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.myDay-content {
  padding: 0px;
  flex: 1;
  overflow-y: auto; /* Make the content scrollable */
  display: flex;
  justify-content: center; /* Center the content vertically */
  align-items: center; /* Center the content horizontally */
}

.schedule-list {
  display: flex;
  flex-direction: column;
  width: 100%; /* Take full width of the screen */
  height: 100%; /* Take full height of the container */
  justify-content: flex-start; /* Align items to the top */
}

.no-schedules {
  font-size: 1.5em;
  color: var(--text-secondary);
  text-align: center;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  height: 100%; /* Take full height to center the message */
}

h3 {
  margin: 10px 0px 0px 20px; /* Add margin to h3 elements */
}