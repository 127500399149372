/* src/components/ScheduleExecutionCheckInPage.css */
.schedule-execution-checkin-page {
  background-color: var(--background-page);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.counter-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.counter-button {
  min-width: 50px;
  min-height: 50px;
  border: 1px solid gray;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}

.counter-display {
  margin: 0 20px;
  font-size: 24px;
}

.card-margin {
  margin-left: 40px;
  margin-right: 40px;
}

.page-title {
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
}

.count-explanation {
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
}

#checkin-buttons {
  position: fixed;
  bottom: 20px;
  left: 30px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column; /* Change to column layout */
  justify-content: space-between;
  width: calc(100% - 50px);
  box-sizing: border-box;
}

#first-button {
  margin-bottom: 10px;
}