.schedule-execution-area-list-page {
  font-family: Arial, sans-serif;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.section h2 {
  background-color: var(--accent-accent);
  color: white;
  padding: 10px;
  margin: 0;
  cursor: pointer; /* Add this line */
}

.item-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.item-row input[type="checkbox"] {
  margin-right: 10px;
}

.item-row button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
  margin-left: auto;
}

.finish-button {
  background-color: var(--accent-success);
  color: white;
  padding: 10px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}