/* src/components/SearchBar.css */
.search-bar {
  display: flex;
  align-items: center;
  background-color: var(--background-content);
  border-radius: 4px;
}

.search-bar-inner {
  display: flex;
  align-items: center;
  /* background-color: #f0f0f0; */
  padding: 10px 20px;
  border-radius: 4px;
  width: 100%;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: var(--background-page);
  outline: none;
}