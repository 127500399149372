.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
}

.logo {
  width: 350px; /* Adjust the size as needed */
  margin-bottom: 20px; /* Space below the logo */
}