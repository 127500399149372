/* src/components/CompanyModal.css */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h4 {
  margin-top: 0;
  margin-bottom: 0;
}


.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.close-button svg {
  color: black;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 8px 0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

li:hover {
  background-color: #f0f0f0;
}

li.selected {
  background-color: #007bff;
  color: white;
}