/* src/components/NavBar.css */
.navbar {
  display: flex;
  align-items: center;
  justify-content: center; /* Centering the title */
  padding: 10px;
  background-color: #f8f8f8; /* Replace with the actual color code of your bottom tab bar */
  color: #000; /* Black text */
  border-bottom: 1px solid #ccc; /* Adding a separator */
  height: 30px; /* Set the height as needed */
  position: relative; /* To position the back button */
}

.back-button {
  position: absolute;
  left: 10px; /* Adjust the position as needed */
  background: none;
  border: none;
  color: #000; /* Black text */
  font-size: 16px; /* Adjust the font size as needed */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.right-button {
  position: absolute;
  right: 10px; /* Adjust the position as needed */
  background: none;
  border: none;
  color: var(--text-primary); /* Black text */
  font-size: 14px; /* Adjust the font size as needed */
  cursor: pointer;
  display: flex;
  align-items: center;
}