/* src/components/SegmentedControl.css */
.segmented-control {
  display: flex;
  justify-content: center;
  margin: 6px 20px;
  overflow: hidden;
}

.segmented-control-tab {
  flex: 1;
  padding: 6px 0px;
  background-color: var(--separator-color);
  color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
  font-weight: 500;
}

.segmented-control-tab:not(:last-child) {
  border-right: none;
}

.segmented-control-tab.active {
  background-color: var(--accent-accent);
}