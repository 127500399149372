/* src/styles/InfoCard.css */
.info-card {
  display: flex;
  flex-direction: column;
}

.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-secondary);
}

.info-content {
  display: flex;
  align-items: center;
}

.profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 16px;
}

.info-details {
  display: flex;
  flex-direction: column;
}

.info-detail-row {
  margin-bottom: 4px;
}

.accent-tint {
  color: var(--accent-accent);
  background-color: transparent;
  font-size: 1.2em; /* Slightly bigger font size */
}

.primary-color {
  color: var(--text-primary);
  background-color: transparent;
}

.secondary-color {
  color: var(--text-secondary);
  background-color: transparent;
}