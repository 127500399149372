.link-associate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center contents vertically */
  height: 100vh; /* Full viewport height */
  padding: 20px;
}

.box {
  /* width: 100vh; */
  /* max-width: 600px; */
  border: 1px solid #ccc;
  margin: 20px; /* Add 20px margin */
  padding: 10px; /* Add padding inside the box */
  border-radius: 8px;
  background-color: #f9f9f9;
}

.link-button:hover {
  background-color: #0056b3;
}
#link-button {
  position: fixed;
  bottom: 20px;
  left: 20px; /* 20px margin on the left */
  right: 20px; /* 20px margin on the right */
  z-index: 1000;
  width: calc(100% - 40px); /* Full width minus 20px margin on each side */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}