.schedule-execution-taskList-page {
  font-family: Arial, sans-serif;
  position: relative;
}

.task-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.task-item span {
  flex: 1;
}

.task-item input[type="text"],
.task-item input[type="date"],
.task-item input[type="number"] {
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: right; /* Right-align the text */
  width: 150px; /* Set a fixed width for the inputs */
}

.task-item .task-switch {
  margin-left: 10px;
  width: auto;
  flex-shrink: 0;
  max-width: 50px;
}

#move-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0; /* Adjust padding to fit the full width */
  box-sizing: border-box;
  text-align: center; /* Center the text */
  background-color: #007bff; /* Add background color for better visibility */
  color: white; /* Add text color for better visibility */
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor to pointer */
}