/* src/screens/TicketsScreenModal.css */
.overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal-header h3 {
  flex: 1;
  text-align: center;
  margin: 0;
}

.close-button {
  position: absolute;
  right: -10px;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-content {
  margin-top: 20px;
  margin-right: -20px;
}

#reset-button {
  position: fixed;
  bottom: 110px;
  left: 60px; /* 20px margin on the left */
  right: 60px; /* 20px margin on the right */
  z-index: 1000;
  width: calc(100% - 120px); /* Full width minus 20px margin on each side */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

#show-button {
  position: fixed;
  bottom: 60px;
  left: 60px; /* 20px margin on the left */
  right: 60px; /* 20px margin on the right */
  z-index: 1000;
  width: calc(100% - 120px); /* Full width minus 20px margin on each side */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}