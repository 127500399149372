/* src/components/LoadingIndicator.css */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Take full height to center the spinner */
}

.loading-indicator svg {
  animation: spin 0.6s linear infinite;
  color: var(--accent-accent); /* Change this to your desired color */
  font-size: 2em; /* Adjust the size as needed */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}