/* src/screens/TicketsScreen.css */
.tickets-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.tickets-list {
  flex: 1;
  overflow-y: auto;
}

.fab {
  position: absolute;
  align-self: flex-end;
  bottom: 20px;
  right: 20px;
}