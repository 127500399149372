.card {
  margin: 20px;
  padding: 20px;
}

h1 {
  text-align: center; /* Center the h1 text */
}


.schedule-execution-start-page {
  background-color: var(--background-page);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#start-button {
  position: fixed;
  bottom: 20px;
  left: 20px; /* 20px margin on the left */
  right: 20px; /* 20px margin on the right */
  z-index: 1000;
  width: calc(100% - 40px); /* Full width minus 20px margin on each side */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}