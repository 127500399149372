/* src/components/RowItem.css */
.row-item {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 0.5px solid var(--separator-color)
}

.row-item-left {
  margin-right: 10px;
}

.row-item-right {
  flex: 1;
}

.row-item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row-item-image {
  width: 18px;
  height: 18px;
}

.row-item-title {
  color: var(--text-primary);
}

.row-item-details {
  color: var(--text-secondary);
  text-align: right;
}

.row-item-secondary-title {
  color: var(--text-primary);
}

.row-item-secondary-details {
  color: var(--text-secondary);
}

.row-item-indicator {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: var(--text-secondary);
}