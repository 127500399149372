/* src/components/Button.css */
.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.button.primary {
  background-color: var(--accent-accent);
  color: var(--background-content);
}

.button.secondary {
  background-color: var(--text-secondary);
  color: var(--background-content);
}

.button.destructive {
  background-color: var(--accent-destructive);
  color: var(--background-content);
}

/* Styles for disabled state */
.button:disabled {
  background-color: #d3d3d3; /* Light grey background */
  color: #a9a9a9; /* Dark grey text */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  opacity: 0.6; /* Optional: reduce opacity */
}