.ticket-details-container {
  position: relative;
  background-color: var(--background-page);
}

.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ticket-details-content {
  display: flex;
  flex-direction: column;
}

.ticket-details-field {
  margin: 10px 20px;
}

.text-field-background {
  background-color: var(--background-content);
}

.gallery-container {
  padding: 10px 20px;
}