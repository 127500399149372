/* src/screens/ProfileScreen.css */
.profile-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.profile-content {
  padding: 20px;
  flex: 1;
}

.change-button {
  background-color: inherit;
  color: var(--accent-accent);
  font-size: 0.8em;
}

#sign-out-button {
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.version-number {
  margin-left: 20px;
  margin-bottom: 20px;
}