/* src/navigation/TabNavigator.css */

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 60px; /* Adjust this value based on the height of your tab bar */
}

.tab-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8f8f8;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: center;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}

.tab-list {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.tab-item {
  flex: 1;
  text-align: center;
  padding: 10px 0;
}

.tab-item a {
  text-decoration: none;
  color: var(--text-secondary);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-item a.active {
  color: var(--accent-accent);
}

.tab-item a span {
  font-size: 12px;
  margin-top: 4px; /* Space between icon and text */
}