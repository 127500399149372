/* src/screens/ReportsScreen.css */
.reports-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.reports-list {
  flex: 1;
  overflow-y: auto;
}