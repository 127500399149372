/* src/screens/ScheduleExecutionScreen.css */
.page-forward-enter {
  opacity: 0;
  transform: translateX(100%);
}
.page-forward-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.page-forward-exit {
  opacity: 1;
  transform: translateX(0);
}
.page-forward-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

.page-backward-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.page-backward-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.page-backward-exit {
  opacity: 1;
  transform: translateX(0);
}
.page-backward-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}